
















import Vue from 'vue';
import NCAlayerService from '@/services/ncalayer'; // adjust the path as necessary

export default Vue.extend({
  data() {
    return {
      textToSign: '',
      signResult: '',
      error: '',
      iin: ''
    };
  },
  beforeDestroy() {
      NCAlayerService.disconnect();
  },
  methods: {
    async signText() {
      try {
        const response = await NCAlayerService.signData(this.textToSign);
        this.signResult = response.result;
        console.error(response.error);
        this.error = response.error;
      } catch (error) {
          this.signResult = error.error;
      }
    },
    async signTextParams() {
      try {
        const response = await NCAlayerService.signData(this.textToSign, {iin: "123456789011"});
        this.signResult = response.result;
        console.error(response.error);
        this.error = response.error;
      } catch (error) {
          this.signResult = error.error;
      }
    },
  },
});
